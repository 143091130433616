import { ApiClient } from 'shop-packages';

import ActionForm, { displayErrors } from '../../components/form-action';

class GuestForm {
  constructor($form) {
    this.$form = $form;
    this.$email = $form.find('input[name="user_email"]');

    const form = ActionForm($form);

    form.action = ApiClient.user.guestLogin;
    form.beforeAction = () => {
      form.actionParams = [
        this.$email.val(),
      ];
    };

    form.onSuccess = (response) => {
      this.onSuccess(response);
    };

    form.onError = err => {
      this.onError(err);
    }
  }

 
  async emailSub() {
   await fetch('/email-subscription/', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          email: this.$email.val(),
          gender: null
      })
  })
  .then(() => {})
  .catch(() => {});
  }

  async onSuccess(response) {
    if (response.data && response.data.errors) {
      displayErrors(response.data.errors, this.$form);
      return;
    }
    await this.emailSub();
    window.location.reload();
  }

  onError() {

  }
}

export default GuestForm;