import Component from "../../../components/dom/component";
import MiniBasketListItem from "./mini-basket-list-item";
import MiniBasketList from "./mini-basket-list";
import { basketPendingSelector, basketReducer } from "shop-packages/redux/basket/selectors";
import store from 'shop-packages/redux/store'
import { fetchBasket } from "shop-packages/redux/basket/actions";
import observe from "shop-packages/redux/connectSelector";
import { getAnalyticsProductFromLocalStorage } from "../../../utils";
export default class MiniBasket extends Component {
  static selector = ".js-mini-basket";

  constructor() {
    super($(MiniBasket.selector));
    this.$totalQuantity = this._$.find('.js-mini-basket-total-quantity');
    this.$totalAmount = this._$.find('.js-mini-basket-total-amount');
    this.basketList = new MiniBasketList(this._$).setViewHolder(MiniBasketListItem);
    this.$loader = this._$.find('.js-mini-basket-loader');
    this.$basketFullScreenContent = this._$.find('.js-mini-basket-full-screen-content');
    this.$basketContainer = this._$.find('.js-mini-basket-container');
    this.$closeButton = this._$.find('.js-mini-basket-close-button');
    this.$totalAmountWrapper = this._$.find('.js-mini-basket-total-amount-wrapper');
    this.$continueButton = this._$.find('.js-mini-basket-continue-button');
    this.$emptyBox = this._$.find('.js-empty-box');

    const setContentDisplay = (value) => (e) => {
      e.stopPropagation();
      const htmlBodySelector = $('html,body');
      const scrollStatus = value === 'block' ? 'overflow-hidden' : '';
      this.$basketFullScreenContent.css("display", value);
      htmlBodySelector.removeClass('overflow-hidden');
      htmlBodySelector.addClass(scrollStatus);
    };

    this._$.on('click', setContentDisplay('block'));
    this.$closeButton.on('click', setContentDisplay('none'));
    this.$basketFullScreenContent.on('click', setContentDisplay('none'));
    this.$basketContainer.on('click', (e) => e.stopPropagation());

    observe(basketReducer).subscribe(({ basket, pending }) => {
      if (!basket || pending) return;
      const { basketitem_set } = basket;

      this.$totalQuantity.html(basket.total_quantity);
      this.$totalAmount.html(basket.total_amount);

      let continueButtonText,continueButtonLink;

      if(basket.total_quantity > 0){
        continueButtonText = 'Alışverişi Tamamla';
        continueButtonLink = window.location.origin + '/baskets/basket';
        this.$continueButton.removeClass('d-none');
        this.$totalAmountWrapper.removeClass('d-none');
        this.$emptyBox.addClass('d-none');
      }else {
        this.$totalAmountWrapper.removeClass('d-flex');
        this.$totalAmountWrapper.addClass('d-none');
        this.$emptyBox.removeClass('d-none');
        this.$continueButton.addClass('d-none');
      }
      
      this.$continueButton.html(continueButtonText);
      this.$continueButton.attr('href',continueButtonLink);
    });

    observe(basketPendingSelector).subscribe(pending => {
      this.$loader.css("display", pending ? 'block' : 'none');
    });
  }
}

$('.js-analytic-data').on('click',function(e){
  e.preventDefault();
  dataLayerMiniBasket();
  observe(basketReducer).subscribe(({ basket, pending }) => {
    if (!basket || pending) return;
    const { basketitem_set } = basket;
    const basket_data = [];

    basketitem_set.forEach((item,index) => {
      const itemDiscountAmount = (+item.discount_amount / item.quantity).toFixed(2);
      const discountRate = ((itemDiscountAmount / +item.retail_price) * 100).toFixed(2);
      const isDiscount = +item.discount_amount > 0 ? 'İndirimli' : 'İndirimsiz';
      const analyticProduct = getAnalyticsProductFromLocalStorage(item.product.base_code);

      basket_data.push({
        item_name: capitalizeWords(item.product.name),
        item_id: item.product.base_code,
        price: +item.product.price,
        discount: +itemDiscountAmount,
        item_last_price: +item.product.price - itemDiscountAmount,
        item_brand: capitalizeWords(item.product.attributes_kwargs?.integration_marka?.label),
        item_category: capitalizeWords(item.product.attributes.integration_ust_kategori), 
        item_category2: capitalizeWords(item.product.attributes.integration_kategori),
        item_list_id: analyticProduct?.listIdx || '',
        item_list_name: capitalizeWords(analyticProduct?.listName) || capitalizeWords(item.product.attributes.integration_alt_kategori),
        item_category3: capitalizeWords(item.product.attributes.integration_alt_marka),
        item_list_location: 'Basket', 
        index: index + 1,
        item_variant: capitalizeWords(item.product.attributes_kwargs?.integration_renk?.label), 
        quantity: item.quantity,
        item_sku: item.product.sku,
        item_season: capitalizeWords(item.product.attributes.integration_season),
        item_material: capitalizeWords(item.product.attributes_kwargs?.integration_karisim?.label),
        item_hierarchy: `${item.product.attributes_kwargs?.integration_ust_kategori?.label} | ${item.product.attributes_kwargs?.integration_kategori?.label}`,
        item_discount_status: isDiscount,
        item_status: 'Standart',
        stock_status: 'in_stock',
        discount_rate: +discountRate,
        item_size: item.product.attributes.integration_beden1,
        coupon: basket?.voucher_code || "",
        new_item_group_id: item.product.attributes.integration_urunkodu || ""
      });
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: 'TRY',
        value: +basket.total_amount,
        items: basket_data
      }
    })
  });
})
function capitalizeWords(string) {
  if (!string) return "";
  return string.split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
}
function dataLayerMiniBasket() {
  
  window.dataLayer.push({
    event: 'gaEvent',
    action: 'Cart View',
    category: 'Cart Pop Up',
    value: 0,
    noninteraction: 'false'
  });
}
