import { thumbnail } from "shop-packages/utils";
import Template from "../../../../components/dom/template";
import RemoveFromCartButton from "../../../../components/button-remove-from-cart";
import QuantityChangeButton from "../../../../components/button-change-quantity";
import * as actionTypes from "shop-packages/analytics/action-types";
import {pushEvent} from "shop-packages/analytics";

export default class MiniBasketListItem extends Template {
  static selector = ".js-mini-basket-item";

  constructor(_$, data) {
    super(_$);
    this.$image = this._$.find(".js-mini-basket-item-image");
    this.$imageLink = this._$.find(".js-mini-basket-item-image-link");
    this.$productName = this._$.find(".js-mini-basket-item-product-name");
    this.$buttonQuantityIncrement = this._$.find(".js-mini-basket-item-quantity-increment-button");
    this.$inputQuantity = this._$.find(".js-mini-basket-item-quantity");
    this.$buttonQuantityDecrease = this._$.find(".js-mini-basket-item-quantity-decrease-button");
    this.$retailPrice = this._$.find(".js-mini-basket-item-retail-price");
    this.$price = this._$.find(".js-mini-basket-item-price");
    this.$errorHolder = this._$.find(".js-basket-item-error");
    this.$buttonRemove = this._$.find(".js-mini-basket-item-remove-button");
    this.$productSize = this._$.find(".js-mini-basket-item-product-size");
    this.$productPattern = this._$.find(".js-mini-basket-item-product-pattern");

    this.$priceCurrency = this._$.find('.js-mini-basket-item-price-currency');
    this.$retailPriceCurrency = this._$.find('.js-mini-basket-item-retail-price-currency');

    this.setProps({ basketItem: data })
  }

  get _miniBasketItem() {
    return this.props.basketItem;
  }
  capitalizeWords(string) {
    if (!string) return "";
    return string.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  }
  render = () => {
    const { basketItem } = this.props;
    const { product } = basketItem;
    const productPatternHTML = typeof product.attributes.integration_kalip_ust !== "undefined" ? product.attributes.integration_kalip_ust : product.attributes.integration_kalip_alt;

    const priceCurrency = parseInt(product.price) > 0 ? 'TL' : '';
    const retailPrice = (parseInt(product.retail_price) > 0 && product.retail_price !== product.price) ? product.retail_price : '';
    const retailPriceCurrency = (parseInt(product.retail_price) > 0 && product.retail_price !== product.price) ? 'TL' : '';

    const pushAnalyticsData = (actionType , pk) => {
      if (pk == product.pk){
        const analyticsData = {
          type : actionType,
          payload : {
            products : [
              {
                id:  product.attributes.integration_renk_kodu && product.attributes.integration_beden1 ? product.base_code + product.attributes.integration_renk_kodu + product.attributes.integration_beden1 : product.base_code,
                sku:  product.sku ,
                name:  product.name,
                price:  product.price ,
                quantity:  basketItem.quantity ,
                brand:  product.attributes_kwargs?.integration_marka?.label,
                variant:  product.attributes_kwargs?.integration_renk?.label,
                category:  product.attributes_kwargs?.integration_kategori?.label,
                dimension10 :  product.attributes.integration_season,
                dimension11 :  product.attributes_kwargs?.integration_karisim?.label,
                dimension12:  product.attributes_kwargs?.integration_ust_kategori?.label + '|' + product.attributes_kwargs?.integration_kategori?.label,
                dimension13:  product.attributes_kwargs?.integration_kalip_ust?.label ,
                dimension14:  product.base_code + '.' + product.attributes.integration_renk ,
                dimension15:  product.sku ,
                dimension16:  product.retail_price ? product.retail_price : price ,
                dimension17:  product.retail_price != product.price  ? 'indirimli'  :  'indirimsiz',
                dimension18:  'standart',
                dimension19: product.attributes.integration_season,
                dimension20: product.attributes.integration_beden1,
                dimension30: product.pk
              }
            ]
          }
        };
        pushEvent(analyticsData);
      }
    };

    const pushAddToCartEvent = (basket) => {
      const couponCode = basket.voucher_code || ""
      const basketIndex = basket.basketitem_set.findIndex(item => item.product.pk === product.pk)
      const basketItem = basket.basketitem_set.find(item => item.product.pk === product.pk)
      const isDiscount = +basketItem.discount_amount > 0 ? 'İndirimli' : 'İndirimsiz';
      const itemDiscountAmount = (+basketItem.discount_amount / basketItem.quantity).toFixed(2);
      const discountRate = ((itemDiscountAmount / +basketItem.retail_price) * 100).toFixed(2);
      const eventData =   {
        currency: "TRY",
        value: +product.price - itemDiscountAmount,
        items: [
           {
            item_id:  product.base_code ,
            item_name:  capitalizeWords(product.name) ,
            currency: "TRY",
            discount:  +basketItem.discount_amount ? +basketItem.discount_amount / basketItem.quantity : 0,
            coupon: couponCode,
            index: basketIndex + 1,
            item_brand:  capitalizeWords(product.attributes_kwargs?.integration_marka?.label) ,
            item_category:  capitalizeWords(product.attributes.integration_ust_kategori) , 
            item_category2:  capitalizeWords(product.attributes.integration_kategori) ,
            item_category3:  capitalizeWords(product.attributes.integration_alt_marka) ,
            item_list_name:   capitalizeWords(product.attributes.integration_alt_kategori),
            item_list_location : "Basket",
            item_variant:  capitalizeWords(product.attributes_kwargs?.integration_renk?.label) , 
            quantity: basketItem.quantity,
            item_sku:  product.sku ,
            item_season:  capitalizeWords(product.attributes.integration_season) ,
            item_material:  capitalizeWords(product.attributes_kwargs?.integration_karisim?.label?.replace('%', '')) ,
            item_discount_status: isDiscount,
            price: +product.price,
            item_size:  product.attributes.integration_beden1,
            item_last_price: +product.price - itemDiscountAmount,
            stock_status: product.in_stock? "in_stock":"out_of_stock",
            discount_rate:  +discountRate,
            new_item_group_id: product.attributes.integration_urunkodu || ""
           }
         ]
       }
       window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: eventData
      });
    };

    const productImage = (product.productimage_set.length > 0) ? thumbnail(product.productimage_set[0].image, 'basket-list'): '/static_omnishop/img/noimage.jpg';
    this.$image.attr('src', productImage);
    this.$imageLink.attr('href', product.absolute_url);
    this.$productName.html(product.name);
    this.$productName.attr('href', product.absolute_url);
    this.$productSize.html(product.attributes.integration_beden1);
    this.$productPattern.html(productPatternHTML);
    this.$inputQuantity.html(basketItem.quantity);
    this.$retailPrice.html(retailPrice);
    this.$price.html(product.price);
    this.$buttonRemove.data('pk', product.pk);
    this.$priceCurrency.html(priceCurrency);
    this.$retailPriceCurrency.html(retailPriceCurrency);
    

    this.$buttonQuantityIncrement.attr('data-pk', product.pk);
    this.$buttonQuantityIncrement.attr('data-quantity', basketItem.quantity + 1);
    this.$buttonQuantityDecrease.attr('data-pk', product.pk);
    this.$buttonQuantityDecrease.attr('data-quantity', basketItem.quantity - 1);

    this.removeButton = RemoveFromCartButton(this.$buttonRemove, this._miniBasketItem.attributes);
    this.quantityIncrementButton = QuantityChangeButton(this.$buttonQuantityIncrement, this._miniBasketItem.attributes);
    this.quantityDecreaseButton = QuantityChangeButton(this.$buttonQuantityDecrease, this._miniBasketItem.attributes);


    this.quantityIncrementButton.success = (response , pk) => {
        pushAddToCartEvent(response.data.basket);
        pushAnalyticsData(actionTypes.productAdded , pk);
    };

    this.quantityIncrementButton.error = (error) => {
      this.$errorHolder.html(error?.non_field_errors);
    };

    this.quantityDecreaseButton.success = (response , pk) =>{
      pushAnalyticsData(actionTypes.productRemoved , pk)
    };

    this.removeButton.success = (response , pk) => {
      pushAnalyticsData(actionTypes.productRemoved , pk)
    };

  }
}
